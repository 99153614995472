import React, { Component } from 'react';
import { graphql } from 'gatsby';
import Layout from '../components/layout';
import SEO from '../components/seo';
import colors from '../config/color';

class Homepage extends Component {
  render() {
    return (
      <Layout showHeader={true} showHero={false}>
        {/* TODO: SEO */}
        <SEO title="WoWindow" isNoAd={true} />
        {/* <div className="search-bar-up">
          <BigSearchBar categories={data.allWordpressCategory.edges} />
          <Product title={'好物篇章'} edges={data.allWordpressPost.edges} />
          <div className="more-btn-content">
            <Link style={{ textAlign: 'center' }} to={'/posts/page/1'}>
              <div className="more">看更多</div>
            </Link>
          </div>
          <Category edges={data.allWordpressCategory.edges} />
        </div> */}

        <div className="content">
          <h1>關於我們</h1>
          <p>
            <br />
            好物櫥窗起始於 2019 年的冬季。
            <br />
            <br />
            由一群各項領域集結而成的小團隊，
            <br />
            為了生活所需、
            <br />
            為了節能省電、
            <br />
            為了珍惜時光，
            <br />
            擁有滿載的願望清單，
            <br />
            正開始陸續消化。
            <br />
            <br />
            透過文字與知識背景，
            <br />
            將「準備」與「滿足」的過程留下。
            <br />
            <br />
            紀錄下新時代的產品軌跡，
            <br />
            替這所有的曾經留存足跡。
            <br />
            <br />
            希望與你的邂逅，
            <br />
            將好物共享予你。
            <br />
            <br />
            <br />
            <p className="icon-note">
              Icons made by
              <a
                href="https://www.flaticon.com/authors/eucalyp"
                title="Eucalyp"
              >
                &nbsp;Eucalyp&nbsp;
              </a>
              from
              <a href="https://www.flaticon.com/" title="Flaticon">
                &nbsp;www.flaticon.com&nbsp;
              </a>
            </p>
          </p>
        </div>

        <style jsx>{`
          h1 {
            font-size: 24px;
            color: ${colors['dirt-brown']};
            margin: 80px 0px 20px 0px;
            display: flex;
            justify-content: center;
          }

          p {
            text-align: center;
            font-size: 18px;
            margin-bottom: 1.5em;
            display: block;
            -webkit-margin-before: 1em;
            margin-block-start: 1em;
            -webkit-margin-after: 1em;
            margin-block-end: 1em;
            line-height: 1.9;
          }

          .content {
            display: flex;
            align-items: center;
            flex-direction: column;
          }

          .icon-note {
            color: ${colors['dirt-brown-70%']};
            font-size: 14px;
          }

          .icon-note a {
            color: ${colors['dirt-brown']};
            font-size: 12px;
            text-decoration: none;
          }
        `}</style>
      </Layout>
    );
  }
}

export default Homepage;

export const pageQuery = graphql`
  query {
    allWordpressCategory {
      edges {
        node {
          wordpress_id
          name
          slug
        }
      }
    }
    hotCategory: allWordpressCategory(
      sort: { fields: count, order: DESC }
      limit: 8
    ) {
      edges {
        node {
          wordpress_id
          name
          slug
        }
      }
    }
    allWordpressPost(limit: 20) {
      edges {
        node {
          title
          slug
          yoast_meta {
            yoast_wpseo_title
            yoast_wpseo_metadesc
            yoast_wpseo_canonical
          }
          categories {
            wordpress_id
            id
            name
            slug
          }
          tags {
            id
            name
            slug
          }
          featured_media {
            source_url
            title
          }
        }
      }
    }
  }
`;
